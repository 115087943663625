import './CookiesBar.scss';

class CookiesBar {

	constructor(node) {
		this.node = node;
	}

	init() {
		console.log('CookiesBar');

		const noticeBar = document.getElementById('CookiesBar');
		let status = localStorage.getItem('cookies-agreement');

		if (status != 'agreed') {
			noticeBar.classList.add('visible');
		} else {
			noticeBar.classList.add('hidden');
		}

		document.getElementById("CookiesBar-accept").addEventListener("click", function() {
			noticeBar.classList.add('hidden');
			localStorage.setItem('cookies-agreement', 'agreed');
		})

	}

}

JSModules['CookiesBar'] = CookiesBar;
export default CookiesBar;
