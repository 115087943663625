import './Footer.scss';

class Footer {

    constructor(node) {
        this.node = node;
    }

    init() {
    	console.log('Footer');
    }

}

JSModules['Footer'] = Footer;
export default Footer;
