global.JSModules = new Array();

const ComponentManager = new function () {

	this.init = function (node) {

		let nodes = [];

		if (typeof node === 'string') {
			nodes = document.querySelectorAll(node);
		}
		else if (node instanceof Node) {
			nodes = [node];
		}
		else if (node instanceof NodeList) {
			nodes = node;
		}
		else {
			throw 'Bad node type for ComponentManager';
		}


		let modules = [];

		nodes.forEach(function (node) {

			let nodes = [].slice.call(node.querySelectorAll('[data-jsmodule]'));

			if (node.hasAttribute('data-jsmodule')) {
				nodes.push(node);
			}

			modules = modules.concat(nodes.sort(function (a, b) {
				let state = a.compareDocumentPosition(b);

				if ((state & 16) != 0) { return 1; }
				else if ((state & 8) != 0) { return -1; }
				else if ((state & 4) != 0) { return -1; }
				else if ((state & 2) != 0) { return 1; }

				return 0;
			}));

		});

		if (modules) {
			modules.forEach(function (node) {

				if (node._component) {
					return;
				}

				let name = node.dataset.jsmodule;

				if (typeof JSModules[name] === 'undefined') {
					//TO DO - global file should be rewritten to contain config only
					return;
				}

				let component = new (JSModules[name])(node);
				component.init(); // initialize component

				// Track instance
				node._component = component;
				component.node = node;

			});
		}

		//console.log(modules);

	}

};
export default ComponentManager;


global.ComponentManager = ComponentManager;
