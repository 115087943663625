// Defaults
import ComponentManager from "./scripts/ComponentManager";
import Header 			from '../src/Components/Header/Header';
import Footer 			from '../src/Components/Footer/Footer';
import CookiesBar 		from '../src/Components/CookiesBar/CookiesBar';
// import RequestDemoModal	from '../src/Components/RequestDemoModal/RequestDemoModal';
import InfoBar 			from '../src/Components/InfoBar/InfoBar';
import Flexible			from '../src/Layout/Flexible/Flexible';

import {WOW} from '../../node_modules/wowjs';

/**
 * Bootstrap components
 */

import Alert 		from './bootstrap/js/alert'
import Button 		from './bootstrap/js/button'
import Collapse 	from './bootstrap/js/collapse'
import Dropdown 	from './bootstrap/js/dropdown'
import Modal 		from './bootstrap/js/modal'
import Popover 	from './bootstrap/js/popover'
import ScrollSpy 	from './bootstrap/js/scrollspy'
import Tab 			from './bootstrap/js/tab'
import Toast 		from './bootstrap/js/toast'
import Tooltip 	from './bootstrap/js/tooltip'

export {
	Alert,
	Button,
	Collapse,
	Dropdown,
	Modal,
	Popover,
	ScrollSpy,
	Tab,
	Toast,
	Tooltip
}

new WOW({live: false}).init();
