import './Header.scss';

class Header {

	constructor(node) {
		this.node = node;
	}

	init() {
		const navbar = document.getElementById('navbar')
		const heightNavbar = navbar.offsetHeight


		 window.onscroll = function () {
			/**
			 * Menu Fixed
			 */
			 if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
				navbar.classList.add('p-fixed');
				document.body.style.paddingTop = heightNavbar + 'px'
			 } else {
				navbar.classList.remove('p-fixed');
				document.body.style.paddingTop = 0;
			 }

			/**
			 * Menu shrink
			 */
			 if (!document.getElementById('infoBar')) {
				if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
					navbar.classList.add('shrink');
				} else {
					navbar.classList.remove('shrink');
				}
			 }
		 }

		/**
		 * Menu shrink
		 */
		if (!document.getElementById('infoBar')) {
			window.onload = function() {
				const yOffset = window.pageYOffset;
				if (yOffset > 80) {
					navbar.classList.add('shrink');
				}
			}
		} else {
			navbar.classList.add('shrink');
		}

		/**
		 * Disable Bg scrolling
		 */
		document.getElementById("navbar-toggler").addEventListener("click", function() {
			const html = document.querySelector('html');
			if (html.classList.contains('blocked')) {
				html.classList.remove('blocked');
				closeMegamenu();
			} else {
				html.classList.add('blocked');
			}
		});

		/**
		 * Hide menu after click
		 * to do
		 */


		/**
		 * Get elements for megamenu
		*/
		 const menuMainMenu = document.getElementById('menu-main-menu')
		 const megamenu = document.querySelectorAll('.megamenu')
		 const hidenMegamenu = document.getElementById('close-megamenu')
 
		/**
		 * Open Megamenu
		*/
		function openMegamenu (el) {
			menuMainMenu.classList.add('open-megamenu');
			el.dataset.open = true;
			el.classList.add('active');
		}

 		/**
		 * Close Megamenu
		*/
		function closeMegamenu () {
			[...megamenu].map((e) => {
				menuMainMenu.classList.remove('open-megamenu');
				e.dataset.open = false;
				e.classList.remove('active');
			})
		}
		 
		/**
		 * Events open megamenu
		*/
		[...megamenu].map((e) => {
			e.dataset.open = false;
			e.addEventListener("click", (event) => {
				if(window.innerWidth < 1200 && e.dataset.open === 'false') {
					event.preventDefault()
					openMegamenu(e);
				}
			})
		})

		/**
		 * Event close megamenu
		*/
		hidenMegamenu.addEventListener("click", () => {
			closeMegamenu();
		});	
	}
}

JSModules['Header'] = Header;
export default Header;
