import './InfoBar.scss';

class InfoBar {

	constructor(node) {
		this.node = node;
	}

	init() {
		console.log('InfoBar')
	}

}

JSModules['InfoBar'] = InfoBar;
export default InfoBar;


