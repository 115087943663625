import './Flexible.scss';

class Flexible {

    constructor(node) {
        this.node = node;
    }

    init() {
        console.log('Flexible');
    }

}

JSModules['Flexible'] = Flexible;
export default Flexible;
